@tailwind base;
@tailwind components;
@tailwind utilities;

.button {
	@apply font-sans text-sm p-4 border-0 bg-gray-600 text-white-base py-3 px-6 focus:outline-none rounded-md md:h-12 min-w-fit;
}

.input {
	@apply relative block w-full appearance-none rounded-md border border-gray-300 p-4 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500;
}

.input-no-rounded {
	@apply relative block w-full appearance-none border border-gray-300 p-4 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500;
}

.input-select {
	@apply relative block w-full appearance-none border border-gray-300 p-4 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500;
}

.input-select-base {
	@apply relative block w-full appearance-none border border-gray-300 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500;
}

.input-error {
	@apply relative block w-full appearance-none rounded-md border border-red-300 p-4 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500;
}

.shadow-twitter {
	box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.2),
		0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.twitter-squadds {
	background: url('/public/images/twitter-squadds.png');
}
